<template>
  <div class="boxx">
    <div class="bar" ref='bar'></div>
  </div>
</template>

<script>
  export default {
    props: ["cultivatelist"],
    data() {
      return {
        data: [{
            name: '龙腾企业',
            value: [22.50]
          },
          {
            name: '全国百强企业',
            value: [9.82]
          },
          {
            name: '高新企业',
            value: [34.97]
          },
          {
            name: '省科技企业',
            value: [87.09]
          },
          {
            name: '功勋企业',
            value: [25.50]
          },
          {
            name: '绿色印刷企业',
            value: [7.70]
          }
        ],
        cultivatelist2: [],
      }
    },
    mounted() {
      // var box = this.$refs.bar
      // console.log(this.cultivatelist2)
      // this.chart(box, this.data)
    },
    methods: {
    //   chart(box, data) {
    //     var myChart = this.$echarts.init(box);
    //     var option;
    //     var colors = ['#5470C6', '#EE6666'];
    //     option = {
    //       color: colors,
    //       title: {
    //         text: '培育企业年产值分析',
    //         textStyle: {
    //           color: "#333333",
    //           fontSize: 18,
    //         }
    //       },
    //       tooltip: {
    //         trigger: 'axis',
    //         axisPointer: {
    //           type: 'cross'
    //         }
    //       },
    //       grid: {
    //         right: '15%',
    //         bottom: '15%'
    //       },
    //       legend: {
    //         data: ['龙腾企业', '全国百强企业', '高新企业', '省科技企业', '功勋企业', '绿色印刷企业'],
    //         width: "17%",
    //         right: "1%",
    //         selected: {
    //           '龙腾企业': true,
    //           '百强企业': true,
    //           '功勋企业': true,
    //           '省科技企业': true,
    //           '绿色印刷企业': true
    //         }
    //       },
    //       xAxis: [{
    //         type: 'category',
    //         axisTick: {
    //           alignWithLabel: true
    //         },
    //         data: ['2020年']
    //       }],
    //       yAxis: [{
    //         min: 0,
    //         /*max: 100,*/
    //         position: 'left',
    //         axisLine: {
    //           show: true,
    //           lineStyle: {
    //             color: colors[0]
    //           }
    //         },
    //         axisLabel: {
    //           formatter: '{value} 亿元'
    //         },
    //         splitLine: {
    //           show: false
    //         }
    //       }],
    //       series: [{
    //           name: data[0].name,
    //           type: 'bar',
    //           data: data[0].value,
    //           barWidth: 25,
    //           barCategoryGap: "50%",
    //           barGap: "80%",
    //           label: {
    //             show: true,
    //             position: "top"
    //           },
    //           itemStyle: {
    //             normal: {
    //               color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                 offset: 0,
    //                 color: 'rgba(19,194,194,0.75)'
    //               }, ]),
    //             }
    //           },
    //         },
    //         {
    //           name: data[1].name,
    //           type: 'bar',
    //           data: data[1].value,
    //           barWidth: 25,
    //           barCategoryGap: "50%",
    //           barGap: "80%",
    //           label: {
    //             show: true,
    //             position: "top"
    //           },
    //           itemStyle: {
    //             normal: {
    //               color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                 offset: 0,
    //                 color: 'rgba(194,19,89,0.75)'
    //               }, ]),
    //             }
    //           },
    //         },
    //         {
    //           name: data[2].name,
    //           type: 'bar',
    //           data: data[2].value,
    //           barWidth: 25,
    //           barCategoryGap: "50%",
    //           barGap: "80%",
    //           label: {
    //             show: true,
    //             position: "top"
    //           },
    //           itemStyle: {
    //             normal: {
    //               color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                 offset: 0,
    //                 color: 'rgba(194,156,19,0.75)'
    //               }, ]),
    //             }
    //           },
    //         },
    //         {
    //           name: data[3].name,
    //           type: 'bar',
    //           data: data[3].value,
    //           barWidth: 25,
    //           barCategoryGap: "50%",
    //           barGap: "80%",
    //           label: {
    //             show: true,
    //             position: "top"
    //           },
    //           itemStyle: {
    //             normal: {
    //               color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                 offset: 0,
    //                 color: 'rgba(194,19,19,0.75)'
    //               }, ]),
    //             }
    //           },
    //         },
    //         {
    //           name: data[4].name,
    //           type: 'bar',
    //           data: data[4].value,
    //           barWidth: 25,
    //           barCategoryGap: "50%",
    //           barGap: "80%",
    //           label: {
    //             show: true,
    //             position: "top"
    //           },
    //           itemStyle: {
    //             normal: {
    //               color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                 offset: 0,
    //                 color: 'rgba(19,42,194,0.75)'
    //               }, ]),
    //             }
    //           },
    //         },
    //         {
    //           name: data[5].name,
    //           type: 'bar',
    //           data: data[5].value,
    //           barWidth: 15,
    //           barCategoryGap: "50%",
    //           barGap: "80%",
    //           label: {
    //             show: true,
    //             position: "top"
    //           },
    //           itemStyle: {
    //             normal: {
    //               color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                 offset: 0,
    //                 color: 'rgba(101,19,194,0.75)'
    //               }, ]),
    //             }
    //           },
    //         }
    //       ]
    //     };

    //     option && myChart.setOption(option);
    //   }
        chart(box,name,value){
          var myChart = this.$echarts.init(box);
          var option;
          option = {
            title: {
              text: '拟培育企业情况',
              textStyle: {
                color: "#333333",
                fontSize: 18,
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            grid: {
              right: '5%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              data: name
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
              },
              name:"单位:家"
            },
            series: [
              {
                data: value,
                type: 'bar',
                barWidth: 25,
                barCategoryGap: "50%",
                barGap: "80%",
                label: {
                  show: true,
                  position: "top",
                  color:"#000"
                },
                itemStyle: {
                    normal: {
                      color:"#80FFFF"
                    }
                  },
              }
            ]
          };

          option && myChart.setOption(option);
        }

    },


    watch: {
      cultivatelist(n) {
        this.cultivatelist2 = n
        let namearr=[]
        let valuearr=[]
        this.cultivatelist2.forEach(item=>{
            namearr.push(item.cultivateTypeName)
            valuearr.push(item.cultivateCount)
        })
        console.log(this.$refs.bar)
        let box =this.$refs.bar
        this.chart(box,namearr,valuearr)
      }
    }
  }
</script>

<style lang="less" scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;

    .bar {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
  }
</style>
