<template>
	<div class="boxx">
		<h1>特色企业信息库</h1>
		<div class="top">
			<div class="topitem">
				<h1>企业类型</h1>
				<button :class="active=='ltTenantNum'?'active':''" @click="activetype('ltTenantNum')">龙腾企业</button>
				<button :class="active=='billionTenant'?'active':''" @click="activetype('billionTenant')">亿元企业</button>
				<button :class="active=='highTenant'?'active':''" @click="activetype('highTenant')">高新企业</button>
				<button :class="active=='stTenant'?'active':''" @click="activetype('stTenant')">省科技企业</button>
				<button :class="active=='smallLifting'?'active':''" @click="activetype('smallLifting')">小升规企业</button>
				<button :class="active=='invisibleChampion'?'active':''" @click="activetype('invisibleChampion')">隐形冠军</button>
				<button :class="active=='masteryTenant'?'active':''" @click="activetype('masteryTenant')">专精特新小巨人</button>
			</div>
			<div class="topitem">
				<h1>产值收入</h1>
				<button :class="active2=='0'?'active':''" @click="activeproduction(0)">0-100万</button>
				<button :class="active2=='1'?'active':''" @click="activeproduction(1)">100-500万</button>
        <button :class="active2=='2'?'active':''" @click="activeproduction(2)">500-2000万</button>
				<button :class="active2=='3'?'active':''" @click="activeproduction(3)">2000-5000万</button>
        <button :class="active2=='4'?'active':''" @click="activeproduction(4)">5000万及以上</button>
			</div>
		</div>
		<div class="bom">
			<el-table
			    :data="tableData"
			    stripe
				:cell-style="{'text-align':'center'}"
				:header-cell-style="{'text-align':'center','font-size':'16px','font-weight':'bold','color':'#333'}"
				border
			    style="width: 100%">
			    <el-table-column
			      prop="num"
			      label="排名"
			      >
			    </el-table-column>
			    <el-table-column
			      prop="name"
			      label="企业名称"
			      >
			    </el-table-column>
			    <el-table-column
			      prop="type"
			      label="企业类型">
			    </el-table-column>
				<el-table-column
				 prop="production"
				 label="年产值(万元)"
				>
				</el-table-column>
				<el-table-column
				  prop="district"
				  label="所属片区">
				</el-table-column>
				<el-table-column
				  prop="garden"
				  width="150px"
				  label="所属园区">
          -
				</el-table-column>
				<!-- <el-table-column
				  prop="Creditpoints"
				  label="信用分值">
				</el-table-column> -->
				<el-table-column
					fixed="right"
					width="150px"
				  label="操作">
				   <template >
				          <el-button
				            type="text"
				            size="small">
				            更多
				          </el-button>
				        </template>
				</el-table-column>
			</el-table>
			<div class="page">
				 <el-pagination
				      @size-change="handleSizeChange"
				      @current-change="handleCurrentChange"
				      :current-page="page"
				      :page-sizes="[10, 20, 30, 40]"
				      :page-size="limit"
				      layout="prev, pager, next, jumper,sizes,total"
				      :total="count">
				    </el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {listCultivateCompany} from "@/api/cultivate/cultivate"
	export default {
		data() {
			return {
				active: 'ltTenantNum',
				active2:0,
				tableData:[
					{num:1,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',production:"500",district:"城西",garden:'金田工业园区',Creditpoints:'100',},
					{num:2,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',production:"400",district:"城西",garden:'金田工业园区',Creditpoints:'98',},
					{num:3,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',production:"300",district:"城西",garden:'金田工业园区',Creditpoints:'96',},
					{num:4,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',production:"200",district:"城西",garden:'金田工业园区',Creditpoints:'95',},
					{num:5,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',production:"100",district:"城西",garden:'金田工业园区',Creditpoints:'94',},
					{num:6,name:"浙江世博新材股份有限公司",type:'印刷和媒介复制业',production:"300",district:"城西",garden:'金田工业园区',Creditpoints:'20',}
				],
				page:1,
				limit:10,
        count:0,
			}
		},
    created() {
      this.listCultivateCompany()
    },
		methods:{
			activetype(val){
        if(this.active!=''&&this.active==val){
          this.active=""
        }else{
          this.active=val
        }
        this.page=1
        this.listCultivateCompany()
			},
      listCultivateCompany(){
        var _this=this
		listCultivateCompany(this.page,this.limit,this.active).then(res=>{
          console.log(res)
          if(res.data.data){
              this.count=res.data.count
              let arr=[]
              res.data.data.forEach((item,i)=>{
                  let obj={}
                  obj.num=(_this.page-1)*_this.limit+(i+1)
                  obj.name=item.companyName?item.companyName:"-"
                  obj.type=item.industryName?item.industryName:"-"
                  obj.production=item.production?item.production:"-"
                  obj.district=item.industrialArea?item.industrialArea:"-"
                  // obj.garden=item.
                  obj.Creditpoints=item.creditScore?item.creditScore:"0"
                  arr.push(obj)
              })
              this.tableData=arr
          }else{
            this.tableData=[]
          }
        })
      },
			activeproduction(val){
				this.active2=val
			},
			handleSizeChange(val) {
        this.page=1
        this.limit=val
        this.listCultivateCompany()
			},
			handleCurrentChange(val) {
        this.page=val
        this.listCultivateCompany()
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.boxx{
		width: 100%;
		height: 100%;
		background-color: #FFFFFF;
		padding: 4px;
		border-radius: 4px;
		.top{
			width: 100%;
			.topitem{
				display: flex;
				margin: 10px 0;
				align-items: center;
				button{
					padding: 4px;
					margin-left: 5px;
					background-color: #fff;
					border: 1px solid #999;
					border-radius: 4px;
				}
				.active{
					background-color: #379DFC;
					border: 1px solid #379DFC;
					color: #FFFFFF;
				}
				button:hover{
					cursor: pointer;
				}
			}
		}
		.bom{
			margin-top: 20px;
			.page{
				display: flex;
				justify-content: flex-end;
				margin: 20px 0;
			}
		}
	}
</style>
