<template>
  <div class="boxx">
    <div ref='pie' class="pie"></div>
    <div class="num">
      <p>累计企业数量：</p>
      <countTo :startVal='startVal' class="allHouseBoxNum" :endVal='endVal' :duration='1500'></countTo>
    </div>
    <div class="num">
      <p>较上年增加：</p>
      <countTo :startVal='startVal2' class="allHouseBoxNum" :endVal='endVal2' :duration='1500'></countTo>
    </div>
    <div class="btnitem" v-for="(item,i) in firmList" :key="i">
      <p>{{item.name}}</p>
      <p>{{item.value}}<span>家</span></p>
    </div>
  </div>
</template>

<script>
  import countTo from 'vue-count-to';
  export default {
    data() {
      return {
        chooseIndex: null,
        startVal: 0,
        endVal: 3906,
        startVal2: 0,
        endVal2: 69,
        firmList: [{
            name: '全国百强企业：',
            value: 3
          },
          {
            name: '龙腾企业：',
            value: 4
          },
          {
            name: '功勋企业：',
            value: 5
          },
          {
            name: '绿色印刷企业：',
            value: 8
          },
          {
            name: '高新企业：',
            value: 37
          },
          {
            name: '省科技企业：',
            value: 263
          },
        ]
      }
    },
    components: {
      countTo
    },
    mounted() {
      var box = this.$refs.pie
      this.chart(box)
    },
    methods: {
      chart(box) {
        var myChart = this.$echarts.init(box);
        var option;

        option = {
          title: {
            text: '龙港企业基础信息',
            textStyle: {
              color: "#333333",
              fontSize: 18,
            }
          },
          color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#BA7575'
            },
            {
              offset: 1,
              color: '#E4E4E4'
            }
          ]),
          // legend:{
          //     top: '5%',
          //     left: 'center',
          // },
          series: [{
            name: '访问来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              position: 'inside',
              fontSize: '14',
              formatter: param => {
                return param.name + '\n' + param.data.date+'家'
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
                formatter: param => {
                  return param.name + '\n' + param.data.date+'家'
                }
              }
            },
            labelLine: {
              show: false
            },
            data: [{
                value: 200,
                name: '在业',
                date: 0
              },
              {
                value: 200,
                name: '迁出',
                date: 0
              },
              {
                value: 200,
                name: '迁入',
                date: 0
              },
              {
                value: 200,
                name: '吊销注销',
                date: 0
              },
              {
                value: 200,
                name: '停业清算',
                date: 0
              },
              {
                value: 200,
                name: '存续',
                date: 3906
              },
            ]
          }]
        };
        myChart.on('click', param => {
          if (param.dataIndex != this.chooseIndex) {
            //没用选中的取消高亮
            myChart.dispatchAction({
              type: 'downplay',
              seriesIndex: 0,
              dataIndex: this.chooseIndex
            });
          }
          //选中某一条高亮
          this.chooseIndex = param.dataIndex;
          console.log(param)
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: param.dataIndex
          });
        })
        option && myChart.setOption(option);
      }
    },
  }
</script>

<style lang="less" scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;

    .pie {
      width: 100%;
      height: 300px;
      background-color: #FFFFFF;
    }

    background-color: #FFFFFF;
    border-radius: 4px;
  }

  .num {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px;
    margin-left: 2em;

    p {
      width: 120px;
    }
  }

  .btnitem {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 4px;
    margin-left: 2em;

    p:nth-child(1) {
      width: 120px;
      margin-right: 10px;
    }

    p:nth-child(2) {
      font-size: 18px;
      color: #000000;

      span {
        font-size: 16px;
      }
    }
  }

  .allHouseBoxNum {
    // height: 70px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(to bottom, #f94600, #f5de57);
    -webkit-background-clip: text;
    color: transparent;
  }
</style>
