<template>
  <div class="boxx">
    <div class="bar" ref='bar'></div>
  </div>
</template>

<script>
  export default {
    props: ["cultivatelist"],
    data() {
      return {
        cultivatelist2: [],
      }
    },
    mounted() {
    },
    methods: {
        chart(box,name,value){
          var myChart = this.$echarts.init(box);
          var option;
          option = {
            title: {
              text: '小升规培育',
              textStyle: {
                color: "#333333",
                fontSize: 18,
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            legend: {
              data: ['申报数量', '申报成功数量'],
            },
            grid: {
              right: '5%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              data: name
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
              },
              name:"单位:家"
            },
            series: [
              {
                name:"申报数量",
                data: value[0],
                type: 'line',
                label: {
                  show: true,
                  position: "top",
                  color:"#000"
                },
              },
              {
                name:"申报成功数量",
                data: value[1],
                type: 'line',
                label: {
                  show: true,
                  position: "top",
                  color:"#000"
                },
              }
            ]
          };

          option && myChart.setOption(option);
        }

    },


    watch: {
      cultivatelist(n) {
        this.cultivatelist2 = n
        let namearr=["2016","2017","2018","2019","2020"]
        let valuearr=[["39","41","51","60","65"],["19","10","30","40","29"]]
        let box =this.$refs.bar
        this.chart(box,namearr,valuearr)
      }
    }
  }
</script>

<style lang="less" scoped="scoped">
  .boxx {
    width: 100%;
    height: 100%;

    .bar {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
  }
</style>
