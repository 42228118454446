import fetch from "@/api/fetch"

// first
export function cultivateCount(){
	return fetch({
		url:'/apg/cultivation/cultivateCount',
		method:'get',
	})
}
// 特色企业信息库
export function listCultivateCompany(page,limit,cultivateType){
	const data={page,limit,cultivateType}
	return fetch({
		url:'/apg/longGang/company/listCultivateCompany',
		method:'post',
		data
	})
}