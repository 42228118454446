<template>
	<div id="box">
		<div class="box">
      <div class="boxtop">
        <div class="boxitem" v-for="item in cultivatelist" :key="item.cultivateType">
            <p>{{item.cultivateCount}}<span>(家)</span></p>
            <h1>{{item.cultivateTypeName}}</h1>
        </div>
      </div>
			<div class="left">
				<div class="lefttop">
          <div class="leftitem">
            <cultivatebar :cultivatelist="cultivatelist"></cultivatebar>
          </div>
          <div class="leftitem">
            <cultivateline :cultivatelist="cultivatelist"></cultivateline>
          </div>
				</div>
				<div class="leftbom">
					<information></information>
				</div>
			</div>
			<!-- <div class="right">
				<cultivatepie></cultivatepie>
			</div> -->
		</div>
	</div>
</template>

<script>
	import cultivatebar from "@/components/cultivate/cultivatebar"
	import information from "@/components/cultivate/information"
	import cultivatepie from "@/components/cultivate/cultivatepie"
  import cultivateline from "@/components/cultivate/cultivateline"
  import {cultivateCount} from "@/api/cultivate/cultivate"
	export default {
    data() {
      return {
        cultivatelist:[],//培育类型分类
      }
    },
    created() {
        this.first()
    },
    methods:{
      first(){
        cultivateCount().then(res=>{
          console.log(res)
          if(res.data.data){
              this.cultivatelist=res.data.data
          }
        })
      }
    },
		components:{
			cultivatebar,
			information,
      cultivateline
			// cultivatepie
		}
	}
</script>

<style lang="less" scoped="scoped">
	.box{
		width: 100%;
		background-color: #f2f3f5;
		border-radius: 4px;
		min-height: 600px;
		// display: flex;
    .boxtop{
      width: 100%;
      height: 150px;
      margin-bottom: 20px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .boxitem{
        width: 150px;
        height: 100px;
        text-align: center;
        line-height: 50px;
        background-color: #fff;
        border-radius: 8px;
        p{
          font-weight: bold;
        }
      }
    }
		.left{
			width: 100%;
			.lefttop{
				width: 100%;
				height: 198px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftitem{
          width: 49%;
          height: 100%;
        }
			}
			.leftbom{
				width: 100%;
				min-height: 524px;
				margin-top: 20px;
			}
		}
		.right{
			width: 33%;
			min-height: 600px;
			margin-left: auto;
		}
	}
</style>
